'use client';

import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { usePathname } from 'next/navigation';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

import Navbar from '@/app/ui/Navbar';

const Wrapper = ({ children }) => {
	const pathname = usePathname();

	useEffect(() => {
		if (pathname === '/privacy-policy') return;
		CookieConsent.run({
			guiOptions: {
				consentModal: {
					layout: 'box',
					position: 'bottom left',
					equalWeightButtons: true,
					flipButtons: false
				},
				preferencesModal: {
					layout: 'box',
					position: 'right',
					equalWeightButtons: true,
					flipButtons: false
				}
			},
			categories: {
				necessary: {
					readOnly: true
				},
				analytics: {}
			},
			language: {
				default: 'en',
				autoDetect: 'browser',
				translations: {
					en: {
						consentModal: {
							title: 'We use cookies!',
							description:
								'Our website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. Tracking will be enabled only upon your explicit consent.',
							acceptAllBtn: 'Accept all',
							acceptNecessaryBtn: 'Reject all',
							footer: '<a href="/privacy-policy">Privacy Policy</a>'
						}
					}
				}
			}
		});
	}, [pathname]);

	return (
		<>
			<Navbar />
			{children}
		</>
	);
};

export default Wrapper;
