'use client';

import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useMemo } from 'react';

const ClientThemeProvider = ({ children }) => {
	// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	// const mode = useMemo(() => (prefersDarkMode ? 'dark' : 'light'), [prefersDarkMode]);

	const mode = 'dark';

	const theme = createTheme({});

	// All default styles
	const themeBase = createTheme({
		typography: {
			fontFamily: [
				'Helvetica',
				'-apple-system',
				'BlinkMacSystemFont',
				'Segoe UI',
				'Roboto',
				'Oxygen',
				'Ubuntu',
				'Cantarell',
				'Fira Sans',
				'Droid Sans',
				'Helvetica Neue',
				'sans-serif'
			].join(',')
		},
		breakpoints: {
			values: {
				...theme.breakpoints.values,
				xl: 1600
			}
		}
	});

	const getDesignTokens = mode => ({
		palette: {
			mode,
			...(mode === 'light'
				? {
						background: theme.palette.augmentColor({
							color: {
								main: '#FFFFFF',
								default: '#FFFFFF',
								paper: '#FFFFFF'
							},
							name: 'background'
						}),
						vMaroon: theme.palette.augmentColor({
							color: {
								main: '#DC3C54',
								light: '#FFD3DA'
							},
							name: 'vMaroon'
						}),
						vBlackOlive: theme.palette.augmentColor({
							color: {
								main: '#101A1E'
							},
							name: 'vBlackOlive'
						}),
						vNavyBlue: theme.palette.augmentColor({
							color: {
								main: '#0B1215'
							},
							name: 'vNavyBlue'
						}),
						vGray: theme.palette.augmentColor({
							color: {
								light: '#EDEDEB',
								main: '#2C2C2C',
								dark: '#d4d3d1'
							},
							name: 'vGray'
						}),
						vDirtyWhite: theme.palette.augmentColor({
							color: {
								main: '#FAF9F6'
							},
							name: 'vDirtyWhite'
						})
				  }
				: {
						background: theme.palette.augmentColor({
							color: {
								main: '#0B1215',
								default: '#0B1215',
								paper: '#0B1215'
							},
							name: 'background'
						}),
						vMaroon: theme.palette.augmentColor({
							color: {
								main: '#DC3C54',
								light: '#602831'
							},
							name: 'vMaroon'
						}),
						vBlackOlive: theme.palette.augmentColor({
							color: {
								main: '#101A1E'
							},
							name: 'vBlackOlive'
						}),
						vNavyBlue: theme.palette.augmentColor({
							color: {
								main: '#0B1215'
							},
							name: 'vNavyBlue'
						}),
						vGray: theme.palette.augmentColor({
							color: {
								light: '#EDEDEB',
								main: '#2C2C2C',
								dark: '#d4d3d1'
							},
							name: 'vGray'
						}),
						vDirtyWhite: theme.palette.augmentColor({
							color: {
								main: '#FAF9F6'
							},
							name: 'vDirtyWhite'
						})
				  })
		}
	});

	const paletteTheme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

	// Component default styles
	const componentTheme = createTheme({
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: 'none'
					}
				}
			}
		}
	});

	let volendayWebsiteTheme = createTheme({
		...themeBase,
		palette: paletteTheme.palette,
		components: componentTheme.components
	});
	volendayWebsiteTheme = responsiveFontSizes(volendayWebsiteTheme);

	return (
		<ThemeProvider theme={volendayWebsiteTheme}>
			<CssBaseline />
			<SnackbarProvider
				maxSnack={3}
				autoHideDuration={3000}
				onClose={() => {}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}>
				{children}
			</SnackbarProvider>
		</ThemeProvider>
	);
};

export default ClientThemeProvider;
