import React from 'react';
import Link from 'next/link';
import { Drawer as MUIDrawer, Box, List, ListItem, ListItemButton, ListItemText, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CTA from '@/app/ui/Buttons/CTA';
import VolendayLogoLink from '@/app/ui/VolendayLogoLink';

const Drawer = ({ links, open, onClose, pathname }) => {
	const theme = useTheme();

	return (
		<MUIDrawer
			variant="temporary"
			open={open}
			onClose={onClose}
			ModalProps={{
				keepMounted: true
			}}
			sx={{
				display: { xs: 'block', lg: 'none' },
				'& .MuiDrawer-paper': {
					boxSizing: 'border-box',
					borderTopRightRadius: '12px',
					borderBottomRightRadius: '12px',
					width: '85vw',
					minWidth: 250,
					maxWidth: 350
				}
			}}>
			<Box>
				<Box sx={{ display: 'grid', placeContent: 'center' }}>
					<VolendayLogoLink logoClicked={onClose} />
				</Box>
				<Divider />
				<List>
					{!!links.length &&
						links.map(link => (
							<Link
								key={link.id}
								href={pathname === '/contact-us' ? `/#${link.slug}` : `#${link.slug}`}
								title={link.label}>
								<ListItem onClick={onClose}>
									<ListItemButton>
										<ListItemText
											primary={link.label}
											primaryTypographyProps={{
												sx: {
													color: theme.palette.text.primary,
													fontSize: theme.typography.pxToRem(18)
												}
											}}
										/>
									</ListItemButton>
								</ListItem>
							</Link>
						))}
				</List>
				<Link href="/contact-us">
					<Box sx={{ p: 2 }} onClick={onClose}>
						<CTA label="Get In Touch" />
					</Box>
				</Link>
			</Box>
		</MUIDrawer>
	);
};

export default Drawer;
