import Link from 'next/link';
import Image from 'next/image';
import { Box } from '@mui/material';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';

const VolendayLogoLink = ({ logoClicked }) => {
	const isDarkMode = useIsDarkMode();

	return (
		<Link href="/" title="Volenday Group">
			<Box onClick={logoClicked} sx={{ position: 'relative', width: '150px', height: '50px' }}>
				<Image
					src={isDarkMode ? '/logo-light.svg' : '/logo-dark.svg'}
					alt="Volenday Group Logo"
					title="Volenday Group Logo"
					fill
					sizes="(max-width: 768px) 35vw, 25vw"
					style={{ objectFit: 'contain' }}
					priority
				/>
			</Box>
		</Link>
	);
};

export default VolendayLogoLink;
