'use client';

import React, { useState } from 'react';
import Link from 'next/link';
import { AppBar, Toolbar, IconButton, Box, Button, Container, useMediaQuery } from '@mui/material';
import { usePathname } from 'next/navigation';
import { styled, useTheme } from '@mui/material/styles';
import { toNumber, endsWith } from 'lodash';

import { MenuOutlined } from '@mui/icons-material';

import useIsDarkMode from '@/app/lib/hooks/useIsDarkMode';
import Drawer from '@/app/ui/Navbar/Drawer';
import CTA from '@/app/ui/Buttons/CTA';
import VolendayLogoLink from '@/app/ui/VolendayLogoLink';

const StyledLink = styled(Button)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontSize: theme.typography.pxToRem(16),
	margin: theme.spacing(0, 0.5),
	[theme.breakpoints.up('lg')]: {
		margin: theme.spacing(0, 1),
		fontSize: `clamp(${theme.typography.pxToRem(14)}, 1.2vw, ${theme.typography.pxToRem(18)})`
	}
}));

const links = [
	{
		id: 'ourBusinesses',
		slug: 'our-businesses',
		label: 'Our Businesses'
	},
	{
		id: 'companyValues',
		slug: 'company-values',
		label: 'Company Values'
	},
	{
		id: 'geography',
		slug: 'geography',
		label: 'Geography'
	},
	{
		id: 'executiveTeam',
		slug: 'executive-team',
		label: 'Executive Team'
	}
	// {
	// 	id: 'careers',
	// 	label: 'Careers'
	// },
	// {
	// 	id: 'aboutUs',
	// 	label: 'About Us'
	// },
];

const Navbar = () => {
	const theme = useTheme();
	const isDarkMode = useIsDarkMode();
	const pathname = usePathname();

	const [drawerOpen, setDrawerOpen] = useState(false);

	const handleDrawerToggle = () => {
		setDrawerOpen(prevState => !prevState);
	};

	const getSpacingValueToNumber = value => {
		return toNumber(endsWith(value, 'px') ? value.slice(0, -2) : value) * 2;
	};

	return (
		<>
			<AppBar
				sx={{
					// width: {
					// 	xs: '100%',
					// 	sm: `calc(100% - ${getSpacingValueToNumber(theme.spacing(4))}px)`,
					// 	md: `calc(100% - ${getSpacingValueToNumber(theme.spacing(4))}px)`,
					// 	lg: `calc(100% - ${getSpacingValueToNumber(theme.spacing(8))}px)`
					// },
					// maxWidth: `calc(1920px - ${getSpacingValueToNumber(theme.spacing(8))}px)`,
					// left: '50%',
					// transform: 'translateX(-50%)',
					backgroundColor: 'transparent',
					px: 0
				}}
				component="nav">
				<Toolbar
					disableGutters
					sx={{
						px: 0,
						backgroundColor: isDarkMode ? 'transparent' : '#FFFFFF',
						backdropFilter: 'blur(15px)',
						borderBottom: isDarkMode ? '1px solid rgba(255, 255, 255, 0.3)' : '1px solid rgba(0, 0, 0, 0.3)'
					}}>
					<Container
						maxWidth={false}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}>
						<Box>
							<VolendayLogoLink />
						</Box>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								display: { xs: 'none', lg: 'block' }
							}}>
							{!!links.length &&
								links.map(link => (
									<StyledLink
										component={Link}
										key={link.id}
										href={pathname === '/contact-us' ? `/#${link.slug}` : `#${link.slug}`}
										title={link.label}>
										{link.label}
									</StyledLink>
								))}
						</Box>
						<Link href="/contact-us" title="Contact Us">
							<Box className="extra-buttons" sx={{ display: { xs: 'none', lg: 'block' } }}>
								<CTA label="Get In Touch" />
							</Box>
						</Link>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{
								ml: 2,
								display: { lg: 'none' },
								color: theme => theme.palette.text.primary
							}}>
							<MenuOutlined />
						</IconButton>
					</Container>
				</Toolbar>
			</AppBar>
			<Drawer links={links} open={drawerOpen} onClose={handleDrawerToggle} pathname={pathname} />
		</>
	);
};

export default Navbar;
